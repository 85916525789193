<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('agent.subordinate')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>

    <van-cell-group class="search">
      <van-search
          v-model="search_username"
          show-action
          :placeholder="$t('agent.please_enter_member_name')"
          @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">{{$t("agent.search")}}</div>
        </template>
      </van-search>
    </van-cell-group>

    <div class="main">
        <div class="title">
          <span>{{$t("agent.user_name")}}</span>
          <span>{{$t("agent.account_balance")}}</span>
          <span>{{$t("agent.total_recharge_amount")}}</span>
          <span>{{$t("agent.total_withdrawal_amount")}}</span>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  pulling-text=" " loosing-text=" " loading-text="...">
          <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
          <div v-else class="item_list" v-for="(v,key) in list" :key="key">
            <span>{{ v.username }}</span>
            <span>{{ v.money }}</span>
            <span>{{ v.rmoney }}</span>
            <span>{{ v.wmoney }}</span>
          </div>
          <div style="margin: 10px auto;background: #fff;" >
            <van-pagination v-model="page" :total-items="total" :items-per-page="page_num" :prev-text="$t('agent.pre_page')" :next-text="$t('agent.next_page')"  mode="simple" @change="changePage" />
          </div>
        </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      finished: false,
      page:1,
      page_num:12,
      total:0,
      count:0,
      active: 0,
      list:[],
      search_username:''
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = true;
        this.getSubUseList();
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getSubUseList(){
      this.$http({
        method: 'get',
        data:{page:this.page,username:this.search_username},
        url: 'user_get_sub_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data.data;
          this.total = res.data.total;
          this.page = res.data.page;
          this.page_num = res.data.page_num;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onSearch(){
        this.getSubUseList()
    },
    changePage(page){
      this.page = page;
      this.getSubUseList();
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getSubUseList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .topInfo span.username{
  font-weight: initial;
  color: #9b9b9b;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .statusRow span{
  flex: 1;
}
.item_list .statusRow span:last-child{
  float: right;
}

.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.search{
  margin: 10px 0px;
  padding: 15px;
}
.title{
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, #7e5678, #e6c3a1);
  line-height: 100px;
  font-size: 14px;
  display: flex;
}
.title span{
  display: block;
  width: 25%;
  text-align: center;
  line-height: 100px;
}
.item_list{
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 14px;
  display: flex;
  padding: 0px;
  margin: 2px 0px;
}
.item_list span{
  display: block;
  width: 25%;
  text-align: center;
  line-height: 100px;
}
.red{
  color: red;
}
.green{
  color: green;
}
</style>
