<template>
  <div class="container page">
    <van-nav-bar :title="$t('my.agent_center')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-content">
      <div class="item van-hairline--bottom" @click="toSubordinate()">
        <div class="left">{{ $t('agent.subordinate') }}</div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="item van-hairline--bottom" @click="toRecordOfDay()">
        <div class="left">{{ $t('agent.recordofday') }}</div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back(){
      return window.history.back();
    },
    toSubordinate(){
      this.$router.push({path:'/Subordinate'});
    },
    toRecordOfDay(){
      this.$router.push({path:'/RecordOfDay'});
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .main-content {
  padding: 0 20px;
  background-color: #fff;
}
.container .main-content .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.container .main-content .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container .main-content .item .right img{
  width: 90px;
}
.container .main-content .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .main-content .item .right .desc-cell-number, .container .main-content .item .right .desc{
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.avatarbox{
  padding: 15px;
  color: #000;
  height: 81%;
  background-color: #fff;
}
.avatarbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}

.avatarbox .content .van-image{
  width: 105px;
  height: 105px;
  margin: 2.5%;
  border-radius: 50%;
}
.avatarbox .content{
  padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.avatarbox .content .choose{
  width: 95px;
  height: 95px;
  border: 6px solid #e6c3a1;
}
</style>