<template>
  <div class="welfare-container page">
    <div class="home-top-bar"><span>สวัสดิการ</span></div>

    <div class="welfare-scroll">
      <div class="welfare-list">
        <ul>
          <li @click="toVideo()">
            <div><img src="/img/welfare/video.png"><span>วีดีโอสมาชิก</span></div>
            <div>
              <span></span>
              <div>
                <img src="/img/welfare/new-avatar-1-1.png"><i></i>
              </div>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </li>
          <li @click="toHome()">
            <div><img src="/img/welfare/new.png"><span>สิทธิประโยชน์สำหรับมือใหม่</span></div>
            <div>
              <span>เพิ่งเข้าร่วม ID: 087***</span>
              <div>
                <img src="/img/welfare/new-avatar-2-1.png"><i></i>
              </div>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </li>
          <li @click="dialog=true">
            <div><img src="/img/welfare/nearby.png"><span>คนใกล้ตัว</span></div>
            <div>
              <span>ตรวจพบสาวๆ 999  คนในพื้นที่ไกล้เคียงกับคุณ</span>
              <div><img src="/img/welfare/new-avatar-3-1.png"><i></i></div>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </li>
<!--          <li><div><img src="/img/welfare/fraud.png"><span>防骗指南</span></div></li>-->
        </ul>

        <div v-if="dialog" class="van-overlay" style="z-index: 15; animation-duration: 0.2s;"><!----></div>
        <div v-if="dialog" class="server-dialog">
          <ul>
            <li>ค้นหาเพื่อนเซ็กซ์ในเมืองเดียวกันสำหรับคุณ</li>
            <li>แหล่งข้อมูลทั้งหมดบนแพลตฟอร์มนี้เป็นของแท้ ปลอดภัย เชื่อถือได้และพร้อมให้บริการทั่วประเทศ เลือกผู้หญิงที่คุณชอบและเริ่มออกเดทได้ทุกที่ทุกเวลา~</li>
            <li @click="dialog=false">ติดต่อแผนกต้อนรับโดยเฉพาะ</li>
          </ul>
        </div>
      </div>
<!--      <div class="hot-game">-->
<!--        <div class="hot-title-div"><img src="/img/welfare/new-lottery-title.png"></div>-->
<!--        <div class="hot-items-div">-->
<!--          <ul>-->
<!--            <li>-->
<!--              <div class="pad-content">-->
<!--              <van-image class="van-image" src="/img/welfare/tcsj.png">-->
<!--                <template v-slot:loading>-->
<!--                  <van-loading type="circular"/>-->
<!--                </template>-->
<!--              </van-image>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="pad-content">-->
<!--                <van-image class="van-image" src="/img/welfare/kjsj.png">-->
<!--                  <template v-slot:loading>-->
<!--                    <van-loading type="circular"/>-->
<!--                  </template>-->
<!--                </van-image>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      basicData:[],
      dialog:false
    };
  },
  methods:{
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
      })
    },


    toVideo(){
      this.$router.push({path:'/Video'})
    },
    toHome(){
      this.$router.push({path:'/Home'})
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
  }
}
</script>

<style lang='less' scoped>
.welfare-container{
  height: calc(100% - 1.33333rem)
}

.welfare-container .welfare-scroll{
  width: 100%;
  height: calc(100% - 1.06667rem);
  margin: 0 auto;
  overflow-y: auto
}

.welfare-container .banner{
  height: 4.1984rem
}
.home-top-bar{
  width: 100%;
  height: 1.06667rem;
  padding: 0 0.4rem 0 0.26667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-top-bar span:first-child {
  font-size: .48rem;
}
.home-top-bar span{
  color: #f3d3b8;
}



.welfare-list{
  width: 100%;
  background: linear-gradient(120deg,#212121,#313131)
}

.welfare-list ul{
  width: 100%
}

.welfare-list ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.6rem;
  padding: 0 .26667rem;
  color: #fff
}

.welfare-list ul li>div{
  display: flex;
  align-items: center
}

.welfare-list ul li>div:first-child>img{
  height: .66667rem;
  margin-right: .26667rem
}

.welfare-list ul li>div:first-child>span{
  font-size: .37333rem;
  font-weight: 700
}

.welfare-list ul li>div:nth-child(2) {
  justify-content: center
}

.welfare-list ul li>div:nth-child(2)>span{
  font-size: .32rem;
  color: #868686
}

.welfare-list ul li>div:nth-child(2)>div{
  width: 1.06667rem;
  height: 1.06667rem;
  margin: auto .26667rem;
  position: relative
}

.welfare-list ul li>div:nth-child(2)>div>img{
  height: 1.06667rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: .05333rem
}

.welfare-list ul li>div:nth-child(2)>div>i{
  position: absolute;
  width: .21333rem;
  height: .21333rem;
  background-color: red;
  border-radius: 50%;
  top: -.10667rem;
  right: -.10667rem
}

.welfare-list ul>li:not(:last-child) {
  border-bottom: .02667rem solid #16151b
}



.border-class {
  border-radius: .26667rem;
  border: .02667rem solid #766350
}

.hot-game {
  width: 100%;
  background: linear-gradient(120deg,#212121,#313131);
  padding: .26667rem 0 .53333rem;
  margin: .26667rem 0
}

.hot-game>div {
  width: calc(100% - .53333rem);
  margin: 0 auto
}

.hot-game .hot-title-div {
  height: .8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .26667rem
}

.hot-game .hot-title-div>img {
  height: .53333rem
}

.hot-game .hot-title-div>div {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.hot-game .hot-title-div>div:first-child {
  width: 4rem
}

.hot-game .hot-title-div>div:nth-child(2) span {
  font-size: .32rem;
  color: #979799
}

.hot-game .hot-title-div>div:nth-child(2) .van-icon {
  color: #979799
}

.hot-game .hot-items-div ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.hot-game .hot-items-div ul li {
  width: 45%;
  margin-bottom: .26667rem;
  border-radius: .26667rem;
  background-color: #fff;
  overflow: hidden
}

.hot-game .hot-items-div ul li .pad-content {
  padding-bottom: 56.67%;
  position: relative;
  background-color: #504f4f
}

.hot-game .hot-items-div ul li .pad-content .van-image,.hot-game .hot-items-div ul li .pad-content .van-loading,.hot-game .hot-items-div ul li .pad-content img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.hot-game .hot-items-div ul li .pad-content .overlay-div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center
}

.hot-game .hot-items-div ul li .pad-content .overlay-div span {
  font-size: .37333rem;
  font-weight: 500;
  color: #fff
}



.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.server-dialog{
  position: fixed;
  display: flex;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 8rem;
  height: 4.8rem;
  z-index: 16;
  background-color: #262626;
  border: 0.02667rem solid #7e6a56;
  border-radius: 0.08rem;
}
.server-dialog ul{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.server-dialog ul>li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.24rem;
}
.server-dialog ul>li:first-child {
  color: #e6b98a;
  font-size: .48rem;
  font-weight: 700;
  margin: 0.10667rem;
}
.server-dialog ul>li:nth-child(2) {
  font-size: .37333rem;
  padding: 0 0.66667rem;
  color: #fff;
}
.server-dialog ul>li:nth-child(3) {
  background: linear-gradient(180deg, #efcba7, #dcab76);
  color: #8a5623;
  font-size: .37333rem;
  width: 5.26667rem;
  height: 0.58667rem;
  border-radius: 0.29333rem;
}
</style>
