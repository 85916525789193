import Vue from 'vue';
import App from './App.vue';
import Vant,{Locale} from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import VueI18n from 'vue-i18n';
import { Swipe, SwipeItem } from 'vant';

import 'video.js/dist/video-js.css'


// 引入英文语言包
import th from 'vant/es/locale/lang/th-TH';
Locale.use('th-TH', th);

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(VueI18n);
Vue.use(Swipe);
Vue.use(SwipeItem);

//默认语言ms_my
if(!localStorage.getItem("lang")){
	localStorage.setItem("lang",'th_th')
}

const i18n = new VueI18n({
  globalInjection: true,
  locale: localStorage.getItem("lang"), // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'en_us': require('./assets/languages/en_us.json'),
    'es_spa': require('./assets/languages/es_spa.json'),
    'ms_my': require('./assets/languages/ms_my.json'),
    'in_es': require('./assets/languages/in_es.json'),
    'th_th': require('./assets/languages/th_th.json'),
    'vi_vn': require('./assets/languages/vi_vn.json'),
  }
});
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
