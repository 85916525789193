<template>
  <div class="mine page">
    <div class="wrapper">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
          <div class="header header_bg_100 login_register_100">
            <div class="user-wrapper">
              <div class="van-image van-image--round avatar-img" style="width: 50px; height: 50px;">
                <img src="/img/login/logo.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <div class="user-content">
                <div class="name">
                  <span class="text">ผู้ใช้ : {{this.userInfo.username}}</span>
<!--                  <div class="van-image level-icon">
                    <img src="https://zh-green.oss-cn-hongkong.aliyuncs.com/level/lottery/1.png" class="van-image__img" style="object-fit: contain;">
                  </div>-->
                </div>
<!--                <p class="experience-text" data-v-70b39549="">星空号 : 93324</p>-->
<!--                <p class="experience-text" data-v-70b39549="">剩余观影次数 : 0 次</p>-->
                <p class="experience-text" data-v-70b39549="">คะเเนนความพึงพอใจ: {{this.userInfo.reputation_points}}</p>
              </div>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </div>

          <div class="content">
            <div class="menu more_features_3">
              <div class="menu-item"  @click="doWallet()">
                <div class="van-image menu-item-icon">
                  <img src="/img/mine/qb.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label">กระเป๋าเงินของฉัน</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
              </div>
            </div>

            <div class="menu more_features_3">
              <div class="menu-item" @click="$router.push({path:'/Transactions'});">
                <div class="van-image menu-item-icon" >
                  <img src="/img/mine/jilu.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label" >บันทึกรางวัล</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray"  style="font-size: 14px;"></i>
              </div>
              <div class="menu-item"  @click="doWithdrawal()">
                <div class="van-image menu-item-icon" >
                  <img src="/img/mine/czjl.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label" >การถอนเงิน</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray"  style="font-size: 14px;"></i>
              </div>
              <div class="menu-item" @click="$router.push({path:'/Personalreport'});">
                <div class="van-image menu-item-icon">
                  <img src="/img/mine/dl.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label">รายการพร็อกซี</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray"  style="font-size: 14px;"></i>
              </div>
            </div>

            <div class="menu more_features_3">
              <div class="menu-item" @click="$router.push({path:'/Setbank'});">
                <div class="van-image menu-item-icon">
                  <img src="/img/mine/lc.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label">บัญชีสำหรับการถอนเงิน</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
              </div>
<!--              <div class="menu-item">-->
<!--                <div class="van-image menu-item-icon">-->
<!--                  <img src="/img/mine/ico_5.png" class="van-image__img" style="object-fit: contain;">-->
<!--                </div>-->
<!--                <span class="menu-item-label">援助交际</span>-->
<!--                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>-->
<!--              </div>-->
<!--              <div class="menu-item">-->
<!--                <div class="van-badge__wrapper" position="top-left">-->
<!--                  <div class="van-image menu-item-icon">-->
<!--                    <img src="/img/mine/ico_6.png" class="van-image__img" style="object-fit: contain;">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span class="menu-item-label">我的消息</span>-->
<!--                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>-->
<!--              </div>-->
<!--              <div class="menu-item">-->
<!--                <div class="van-image menu-item-icon">-->
<!--                  <img src="/img/mine/ico_6.png" class="van-image__img" style="object-fit: contain;">-->
<!--                </div>-->
<!--                <span class="menu-item-label">银行卡管理</span>-->
<!--                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>-->
<!--              </div>-->
              <div class="menu-item" @click="showSetting()">
                <div class="van-image menu-item-icon">
                  <img src="/img/mine/seting.png" class="van-image__img" style="object-fit: contain;">
                </div>
                <span class="menu-item-label">ตั้งค่า</span>
                <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
              </div>
            </div>
          </div>

      </van-pull-refresh>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo:{
      },
      menu_top:40
    };
  },
  methods: {
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.$toast(this.$t("reservation.refresh"));
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    exit(){
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Setting'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toNotice(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Notice'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast(this.$t("reservation.refresh"));
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    doLogin(){
        if(localStorage.getItem('token')){
            this.$router.push({path:'/Infomation'});
        }else {
            this.$router.push({path:'/Login'})
        }
    },
    doWallet(){
      this.$router.push({
        name:'Wallet'
      })
    },
    doWithdrawal(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
          if(res.data.is_bank){
            this.$router.push("withdraw");
          }else {
            this.$router.push("Setbank?from=withdraw");
            this.$toast.fail(this.$t("setting.set_bank"));
          }
      })
    },
    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.$t("video.account_out"));
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #16151b;
}
.mine .wrapper {
  position: absolute;
  inset: 0px;
  overflow-y: auto;
}
.mine{
  position: relative;
  bottom: 1.33333rem;
}

.mine .page-bg{
  height: 50vh;
  background: linear-gradient(90deg, transparent, transparent);
}

.mine .wrapper{
  position: absolute;
  inset: 0px;
  overflow-y: auto;
}

.mine .wrapper .pull-text{
  font-size: 0.34667rem;
  color: rgb(255, 255, 255);
}

.mine .wrapper .pull-loading{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mine .wrapper .pull-loading .pull-loading-text{
  margin-left: 0.26667rem;
  font-size: 0.34667rem;
  color: rgb(255, 255, 255);
}

.mine .wrapper .content{
  position: relative;
}

.header {
  padding: 0.93333rem 0px 0.53333rem;
  background-color: rgb(33, 33, 33);
}

.header .nav {
  background-color: rgb(33, 33, 33);
}

.header .nav .right-icon {
  color: rgb(255, 255, 255);
}

.header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 0.4rem;
}

.header .user-wrapper .avatar-img {
  border: 0.05333rem solid rgb(255, 255, 255);
}

.header .user-wrapper .login-content {
  flex: 1 1 0%;
  margin-left: 0.4rem;
}

.header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 0.48rem;
  line-height: 0.58667rem;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.header .user-wrapper .login-content .login-label {
  margin-top: 0.13333rem;
  line-height: 0.42667rem;
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.6);
}

.header .user-wrapper .user-content {
  flex: 1 1 0%;
  margin-left: 0.4rem;
}

.header .user-wrapper .user-content .name {
  display: flex;
  align-items: center;
}

.header .user-wrapper .user-content .name .text {
  font-size: 0.37333rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.header .user-wrapper .user-content .name .level-icon {
  margin-left: 0.13333rem;
  width: 1.06667rem;
  height: 0.53333rem;
  object-fit: contain;
}

.header .user-wrapper .user-content .experience {
  display: flex;
  margin: 0.26667rem 0px;
  width: 80%;
  height: 0.10667rem;
  border-radius: 0.26667rem;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.header .user-wrapper .user-content .experience .experience-content {
  height: 100%;
  border-radius: 0.26667rem;
  background-color: rgb(255, 255, 255);
}

.header .user-wrapper .user-content .experience-text {
  font-size: 0.32rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.header .user-wrapper .use-content-name {
  color: rgb(255, 255, 255);
  margin-left: 0.2rem;
  font-size: 0.42667rem;
}


.more_features_1 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  justify-content: flex-start;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
}

.more_features_1 .menu-item {
  flex-direction: column;
  width: 33.33% !important;
  height: 3.12rem !important;
  justify-content: center !important;
}

.more_features_1 .menu-item .menu-item-icon {
  width: auto !important;
  height: 1.06667rem !important;
  object-fit: contain !important;
  margin-bottom: 0.13333rem !important;
}

.more_features_1 .menu-item:not(:nth-child(3n)) {
  border-right: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_1 .menu-item:not(:first-child, :nth-child(2), :nth-child(3)) {
  border-top: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_2 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
}

.more_features_2 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_2 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_2 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_2 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_3, .more_features_4 {
  box-shadow: none !important;
}

.more_features_3 .menu-item, .more_features_4 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_3 .menu-item .menu-item-label, .more_features_4 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_3 .menu-item .menu-item-icon, .more_features_4 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_3 .menu-item:not(:last-child), .more_features_4 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_5 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.more_features_5 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_5 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_5 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_5 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_5 .menu-item {
  padding: 0px 0.21333rem;
  width: 100% !important;
  height: 0.93333rem !important;
  background-color: rgba(42, 35, 44, 0.6) !important;
  border: none !important;
  margin-bottom: 0.13333rem !important;
}

.more_features_5 .menu-item .menu-item-icon {
  object-fit: contain;
  margin-right: 0.13333rem !important;
  height: 0.48rem !important;
}

.more_features_5 .menu-item .menu-item-label, .more_features_5 .menu-item i {
  color: rgb(255, 255, 255) !important;
}

.menu {
  margin-top: 0.32rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(33, 33, 33);
  margin-bottom: 0.26667rem;
}

.menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 1.86667rem;
  color: rgb(255, 255, 255);
}

.menu .menu-item .menu-item-icon {
  margin-right: 0.26667rem;
  width: 0.53333rem;
  object-fit: contain;
}

.menu .menu-item .menu-item-label {
  font-size: 0.34667rem;
  font-weight: 500;
}
</style>
