<template>
  <div class="container page">
    <van-nav-bar class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <video class="video-js" autoplay style="width: 100%" :src="`/img/live/${$route.query.video}.mp4`"></video>
  </div>
</template>

<script>
// import videojs from "video.js";
// import "videojs-contrib-hls";
export default {
  data() {
    return {
      player:null,
      nowPlayVideoUrl: this.$route.query.video,
      cover:"",
      videoInfo:{},
      live_list:{
        "1":{id:1,name:"โน๋เนส",city:"ลพบุรี",height:"165",tag:["น่ารัก", "คุยเก่ง","ขี้อ้อน"]},
        "2":{id:2,name:"แพม",city:"เชียงใหม่",height:"165",tag:["ขี้อ้อน","เอาใจเก่ง","ไม่งอแง","บริการดี"]},
        "3":{id:3,name:"อ๋อมแอ๋ม",city:"อุดร",height:"160",tag:["ยิ้มง่าย","เอาใจเก่ง","หุ่นแซ่บ"]},
        "4":{id:4,name:"มินตรา",city:"กำแพงเพชร",height:"168",tag:["นิสัยดี","เอาใจเก่ง","ไม่งอแง"]},
        "5":{id:5,name:"วิว",city:"กบินบุรี",height:"158",tag:["ผิวขาวเนียน","นิสัยดี","ขี้เล่น"]},
        "6":{id:6,name:"อีฟ",city:"กรุงเทพ",height:"165",tag:["อวบ","น่าขย้ำ","ยิ้มเก่ง"]},
        "7":{id:7,name:"บีเบลล์",city:"นนทบุรี",height:"158",tag:["ผิวขาว","เอาใจเก่ง","ขี้อ้อน"]},
        "8":{id:8,name:"พิมมี่",city:"ปทุมธานี",height:"165",tag:["ตัวเล็ก","หน้าคม","เอาใจเก่ง"]},
        "9":{id:9,name:"อ้อม",city:"พังงา",height:"160",tag:["อวบอึม","เต็มมือ","ลีลาเด็ด","สวย"]},
        "10":{id:10,name:"ปิ่น",city:"ลพบุรี",height:"162",tag:["เป็นกันเอง","น่ารัก","คุยเก่ง"]},
        "11":{id:11,name:"โบว์ ลิ่งค์",city:"กระบี่",height:"155",tag:["น่ารัก","คุยเก่ง","ขี้อ้อน","ไม่งอแง"]},
        "12":{id:12,name:"หญิง",city:"ลำปาง",height:"160",tag:["น่ารัก","ขี้อ้อน","เอาใจเก่ง"]},
        "13":{id:13,name:"มุนิน",city:"เชียงราย",height:"152",tag:["ยิ้มเก่ง","ขี้อ้อน","เอาใจเก่ง"]},
        "14":{id:14,name:"ใบเฟิร์น",city:"พัทยา",height:"166",tag:["เป็นกันเอง","อัธยาศัยดี","คุยเก่ง"]},
        "15":{id:15,name:"เฟิร์น",city:"อุดรธานี",height:"158",tag:["ตัวเล็ก","นิสัยดี","ยิ้มเก่ง","เอวดี"]}
      }
    };
  },
  methods: {
    back(){
      this.$router.push({path:'Home'})
    },
    // getVideoInfo(){
    //   this.$http({
    //     method: 'get',
    //     data:{id:this.$route.query.video},
    //     url: 'video_get_info'
    //   }).then(res=>{
    //     this.videoInfo = res.data;
    //     this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
    //     this.cover = this.videoInfo.vod_pic;
    //     let videos = document.getElementById('example-video-div');
    //     videos.poster = this.cover;
    //     this.getVideo();
    //   })
    // },
    // getVideo() {
    //   this.player.src([{
    //     src: `/img/live/${this.nowPlayVideoUrl}.mp4`,
    //     type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
    //   }]);
    // }
  },
  mounted(){
    // this.getVideoInfo();
    // const options = {
    //   autoplay: true, // 启用自动播放
    //   muted: true, // 静音，这是大多数浏览器自动播放所必需的
    //   // 其他 video.js 选项...
    // };
    // this.player = videojs("example-video-div", options,() => {
    //   console.log('播放器已就绪，并开始自动播放！');
    //   // this.getVideo();
    // });
  }
};
</script>

<style scoped>
.container{
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
.container #example-video-div{
  width: 100%;
  height: 100%;
}
.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: .26667rem;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: normal;
  height: 100%;
}
.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
