<template>
  <div class="movie-hall page">
    <van-nav-bar class="nav-bar" :title="$t('video.video')">
      <template #left>
        <van-icon name="arrow-left" color="#e6b98a" @click="back()"/>
      </template>
    </van-nav-bar>

    <van-tabs v-model="active" animated swipeable  @change="OnChange">
      <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" ></van-tab>
    </van-tabs>

    <swiper class="movie-wrapper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">
      <swiper-slide v-for="(v,key) in videolitem" :key="key" class="movie-list-tab">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text=" " loosing-text=" " loading-text="...">
            <div class="hot-recommend-div">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                >
                  <div class="list-item" v-for="(group, groupIndex) in groupedVideoList" :key="`group-${groupIndex}`">
                    <div class="movie-list-div" v-for="(v, key) in group" :key="`item-${key}`" @click="toPlayVideo(v.id)">
                      <van-image class="movie-list-item movie_tiem_3" src="/img/home/fengmian.jpg"
                                 >
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                      </van-image>
                      <div class="movie-title-div">{{v.vod_name}}</div>
                    </div>
                  </div>
                </van-list>
            </div>
          </van-pull-refresh>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Toast } from 'vant';
import axios from 'axios';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  computed: {
    groupedVideoList() {
      // 初始化一个空数组来存储结果
      let grouped = [];
      // 遍历原始视频列表
      this.videolist.forEach((item, index) => {
        // 检查当前索引是否为偶数，如果是，则开始一个新组
        if (index % 2 === 0) {
          // 如果是偶数索引，推入一个新的子数组，包含当前和下一个项目（如果存在）
          grouped.push(this.videolist.slice(index, index + 2));
        }
      });
      return grouped;
    }
  },
  methods: {
    back(){
      return window.history.back();
    },
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;
      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t("reservation.refresh"));
      }, 500);
    },
    async checkUrl(url) {
      console.log(url)
      try {
        const response = await axios.get(url, {

          headers: {
            'Content-Type': 'application/json',
            // 其他需要的头部
          }
        });
        console.log(response)
        if (response.status === 200){
          return url;
        }else{
          return `/img/login/logo.png`;
        }
      } catch (error) {
        return `/img/login/logo.png`;
      }
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style lang='less'>
@import "../../assets/css/base.css";
.movie-hall {
  display: flex;
  flex-direction: column;
  background: #282828
}

.movie-hall .van-nav-bar {
  background-color: transparent
}

.movie-hall .van-nav-bar .van-nav-bar__text {
  color: #fff
}

.movie-hall .van-tabs__nav {
  background-color: transparent
}

.movie-hall .van-tabs__nav .van-tab--active {
  color: #e6b98a
}

.movie-hall .van-tabs__nav .van-tabs__line {
  background-color: #e6b98a
}

.movie-hall .movie-wrapper {
  flex: 1;
  width: 100%
}

.movie-hall .movie-wrapper .swiperSlide {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  justify-content: center
}

.movie-hall .no-data {
  height: calc(100% - 1.33333rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.movie-hall .no-data img {
  width: 50%;
  -o-object-fit: contain;
  object-fit: contain
}

.movie-hall .no-data span {
  font-size: .32rem;
  color: #999
}


.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}


.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.movie-hall .movie-wrapper .swiperSlide {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  justify-content: center;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}



.movie-list-tab {
  overflow: auto;
  height: 100%
}

.movie-list-tab .van-pull-refresh {
  height: 100%
}

.movie-list-tab .hot-recommend-div {
  width: calc(100% - .53333rem);
  height: 100%;
  margin: .26667rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto
}

.movie-list-tab .hot-recommend-div .van-list {
  width: 100%;
  height: 100%
}

.movie-list-tab .hot-recommend-div .first-move-list-div {
  width: 100%!important
}

.movie-list-tab .hot-recommend-div .list-item {
  width: 100%;
  min-height: 3.46667rem;
  display: flex;
  justify-content: space-between
}

.movie-list-tab .hot-recommend-div .list-item>.movie-list-div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 4.66667rem
}

.movie-list-tab .hot-recommend-div .list-item>.movie-list-div>div:first-child {
  margin-right: .4rem
}

.movie-list-tab .hot-recommend-div .list-item>.movie-list-div>.movie-title-div {
  width: 98%;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: .26667rem
}

.movie-list-tab .no-data {
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.movie-list-tab .no-data img {
  width: 50%;
  height: 50vw;
  -o-object-fit: contain;
  object-fit: contain
}

.movie-list-tab .no-data span {
  font-size: .32rem;
  color: #999
}

.movie-list-item {
  width: 4.66667rem;
  height: 3.73333rem;
  border-radius: .13333rem;
  background-color: #282828;
  margin-bottom: .26667rem;
  position: relative
}

.movie-list-item .bgImg {
  width: 100%;
  border-radius: .13333rem .13333rem 0 0;
  -o-object-fit: cover;
  object-fit: cover
}

.movie-list-item .NoImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.06667rem
}

.movie-list-item .movie-list-item-bottom {
  position: absolute;
  width: 100%;
  bottom: 0
}

.movie-list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .13333rem
}

.movie-list-item .movie-list-item-bottom .movie-time-div {
  height: .53333rem;
  background-color: rgba(0,0,0,.4)
}

.movie-list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff
}

.movie-list-item .movie-list-item-bottom .movie-time-div span:first-child {
  width: 2.13333rem
}

.movie-list-item .movie-list-item-bottom .movie-title-div {
  height: .8rem;
  background-color: #282828;
  border-radius: 0 0 .13333rem .13333rem
}

.movie-list-item .movie-list-item-bottom .movie-title-div p {
  overflow: hidden;
  white-space: nowrap;
  color: #fff
}

.movie-list-item .movie-list-item-bottom .movie-title-div span {
  font-size: .42667rem;
  color: #fff
}

.movie-list-item .van-dialog {
  width: 8.53333rem;
  z-index: 9999
}

.movie-list-item .play-num {
  position: absolute;
  height: .53333rem;
  bottom: .93333rem;
  right: .26667rem;
  background-color: rgba(0,0,0,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: .05333rem;
  padding: 0 .13333rem
}

.movie-list-tab .hot-recommend-div .list-item>.movie-list-div>.movie-title-div {
  width: 98%;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0.26667rem;
}
.movie_tiem_3{
  margin-bottom: 0.13333rem !important;
}
.movie_tiem_2, .movie_tiem_3,.movie_tiem_4 {
  width: 100% !important;
  height: 2.66667rem !important;
}
.movie-hall .nav-bar {
  background-color: #282828;
  height:auto;
}
.van-image__error-icon {
  color: #dcdee0;
  font-size: 4.267vw;
  display: none;
}
</style>
